import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import { ToastContainer, toast, Zoom } from "react-toastify";
import * as Solid from "@heroicons/react/24/solid";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Numbers } from "../../constants/constant";
import DashboardStructure from "../../components/shared/DashboardStructure/DashboardStructure";
import {
  getSchedulerParlourList,
  deleteSchedulerParlour,
  getSchedulerParlourParticipants,
} from "../../api/scheduler-parlour";
import Carousel from "react-elastic-carousel";
import "./schedulerParlour.scss";
import ModalBox from "../../components/shared/pageComponent/ModalBox";
import CardParlour from "./CardParlour";
import { Button } from "reactstrap";
import FormParlour from "./FormParlour";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
  { width: 2050, itemsToShow: 7 },
  { width: 2350, itemsToShow: 8 },
  { width: 2650, itemsToShow: 9 },
  { width: 2950, itemsToShow: 10 },
];

function SchedulerParlour(props) {
  const [loading, setLoading] = useState(false);
  const [parlours, setParlours] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalOpenForm, setModalOpenForm] = useState(false);

  const toggleModal = () => setModalOpen(prevState => !prevState);
  const toggleModalForm = () => setModalOpenForm(prevState => !prevState);

  const getParlourSubmissions = useCallback(async () => {
    try {
      setLoading(true);
      const parlours = await props.getSchedulerParlourList();
      setParlours(parlours);
    } catch (error) {
      toast("Failed to get songs contest", { type: "error" });
    } finally {
      setLoading(false);
    }
  }, [props]);

  useEffect(() => {
    getParlourSubmissions();
  }, [props, getParlourSubmissions]);

  const handleDeleteParlour = async (parlourId) => {
    confirmAlert({
      title: "Confirm to delete selected parlour",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              setLoading(true);
              const response = await props.deleteSchedulerParlour(parlourId);
              await getParlourSubmissions(true);
              toast(response.msg, { type: "success" });
            } catch (error) {
              toast("Failed to delete songs", { type: "error" });
            } finally {
              setLoading(false);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getParlourParticipants = useCallback(async (parlourId) => {
    try {
      setLoading(true);
      const { data } = await props.getSchedulerParlourParticipants(parlourId);
      setParticipants(data);
      toggleModal();
    } catch (error) {
      toast("Failed to get parlour participants", { type: "error" });
    } finally {
      setLoading(false);
    }
  }, [props]);

  const updateParlour = (parlourUpdate) => {
    const index = parlours.findIndex((p) => p.id === parlourUpdate.id);
    const newParlours = [...parlours];
    newParlours[index] = {...newParlours[index], ...parlourUpdate};
    setParlours(newParlours);
  };

  const addParlour = (parlour) => {
    setParlours([parlour, ...parlours]);
  };

  return (
    <LoadingMask loading={loading} text={"loading..."}>
      <ToastContainer
        position="top-center"
        autoClose={Numbers.twoThou}
        transition={Zoom}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <DashboardStructure title="Scheduler Parlour" history={props.history}>
        <div className="main-scroll">
          <div className="header-parlour">
            <h3 className="title">Parlours Submissions</h3>
            <Button color="primary" size="sm" className="w-auto" onClick={toggleModalForm} >Create Parlour</Button>
            <ModalBox
              isOpen={modalOpenForm}
              ModalTitle="Create Parlour"
              withHeader={true}
              toggle={toggleModalForm}
              className="modal-right">
                <FormParlour 
                  addParlour={addParlour}
                  toggleModalForm={toggleModalForm}
                  setLoading={setLoading}
                  loading={loading}
                />
            </ModalBox>
          </div>
          <div className="parlours">
            {parlours.length === 0 && (
              <div className="empty">Not songs submissions.</div>
            )}
            {parlours.length > 0 && (
              <Carousel
                breakPoints={breakPoints}
                pagination={false}
                renderArrow={({ type, onClick, isEdge }) => {
                  const pointer =
                    type === "PREV" ? (
                      <Solid.ChevronLeftIcon />
                    ) : (
                      <Solid.ChevronRightIcon />
                    );
                  return (
                    <button
                      className="arrow-slider"
                      onClick={onClick}
                      disabled={isEdge}
                    >
                      {pointer}
                    </button>
                  );
                }}
              >
                {parlours.map((parlour) => {
                  return (
                    <CardParlour 
                      key={`parlour-${parlour.id}`}
                      parlour={parlour}
                      loading={loading}
                      handleDeleteParlour={handleDeleteParlour}
                      getParlourParticipants={getParlourParticipants}
                      updateParlour={updateParlour}
                      setLoading={setLoading}
                    />
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </DashboardStructure>
      <ModalBox
        isOpen={isModalOpen}
        ModalTitle="Participants"
        withHeader
        toggle={toggleModal}
        className="modal-right">
        <div>
          {
            participants.length === 0 && (
              <div className="empty">No participants.</div>
            )
          }
          {participants.map((p, index) => (
            <div key={index} className="participant">
              <img className="participant-image" height="43" width="43" alt={p.name} src={p.profile_image_url} loading="lazy" />
              <span className="participant-detail">
                <span className="name">{p.name}</span>
                <span className="email">{p.email}</span>
              </span>
            </div>
          ))}
        </div>
      </ModalBox>
    </LoadingMask>
  );
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  getSchedulerParlourList,
  deleteSchedulerParlour,
  getSchedulerParlourParticipants,
})(withRouter(SchedulerParlour));
