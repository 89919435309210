import axios from "axios";
import "./interceptors";
import { toast } from "react-toastify";

export function getSchedulerParlourList() {
  let url = `admin/scheduler-parlour/all`;

  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_SCHEDULER_PARLOUR_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteSchedulerParlour(parlourId) {
  let url = `admin/scheduler-parlour/delete/${parlourId}`;

  return (dispatch) => {
    return axios
      .delete(url)
      .then((res) => {
        dispatch({
          type: "DELETE_SCHEDULER_PARLOUR",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSchedulerParlourParticipants(parlourId) {
  const url = `admin/scheduler-parlour/${parlourId}/participants`;
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };
}

export function updateSchedulerParlour(parlourId, data) {
  const url = `admin/scheduler-parlour/update/${parlourId}`;

  return (dispatch) => {
    return axios
      .patch(url, data)
      .then((res) => {
        dispatch({
          type: "UPDATE_SCHEDULER_PARLOUR",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function createSchedulerParlour(data) {
  const url = `admin/scheduler-parlour/create`;
  data.start_date = new Date(data.start_date).toISOString();

  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return (dispatch) => {
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: "CREATE_SCHEDULER_PARLOUR",
          data: res.data,
        });
        toast(res.data.msg, { type: "success", autoClose: 1000 * 10 });
        return res.data;
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const { errors, message } = err.response.data;
          if (message) {
            toast(message, { type: "error", autoClose: 1000 * 10 });
          }
          if (errors) {
            for (let key in errors) {
              toast(errors[key], { type: "error", autoClose: 1000 * 10 });
            }
          }
          return;
        }
        toast("Failed to create parlour", { type: "error", autoClose: 1000 * 10 });
        throw err;
      });
  };
}